import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {GetDashboardLIst } from './api';

export const DashboardData = createAsyncThunk('DashboardData', async (_, { rejectWithValue }) => {
    try {
        const response = await GetDashboardLIst();
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});


const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        error: null,
        success: false,
        data: null

    },
    reducers: {
    

    },
    extraReducers: builder => {
        builder
            .addCase(DashboardData.pending, state => {
                state.error = null;
                state.success = false;
            })
            .addCase(DashboardData.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(DashboardData.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
            })


    },
});
export const { } = dashboardSlice.actions;
export default dashboardSlice.reducer;

