import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { DashboardData } from "../../../Reducer/dashboardSlice";
import Layout from "../../Layouts/Layout";

export default function Dashboard() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.data);

  useEffect(() => {
    dispatch(DashboardData());
  }, [dispatch]);

  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12 lg:col-6 xl:col-3">
          <Link to="/Users">
            <div className="card mb-0">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-500 font-medium mb-3">
                  Users
                  </span>
                  <div className="text-900 font-medium text-xl">{data && data.users}</div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-blue-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-user text-blue-500 text-xl" />
                </div>
              </div>
             
            </div>
            </Link>
          </div>
          <div className="col-12 lg:col-6 xl:col-3">
            <div className="card mb-0">
              <div className="flex justify-content-between mb-3">
                <div>
                  <span className="block text-500 font-medium mb-3">
                  Match Makers
                  </span>
                  <div className="text-900 font-medium text-xl">{data && data.makers}</div>
                </div>
                <div
                  className="flex align-items-center justify-content-center bg-orange-100 border-round"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                >
                  <i className="pi pi-user text-orange-500 text-xl" />
                </div>
              </div>
             
            </div>
          </div>
          
        </div>

        
      </Layout>
    </>
  );
}
