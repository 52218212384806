import UserDetails from "../Users/UserDetails";
import AstroDetails from "../Users/AstroDetails";
import FamilyDetails from "../Users/FamilyDetails";
import CarrerDetails from "../Users/CarrerDetails";
import SpiritualDetails from "../Users/SpritualDetails";
import PersonalityDetails from "../Users/PersonalityDetails";
import Gallery from "../Users/Gallery";
import Documents from "../Users/Documents";
import PreferenceDetails from "../Users/PreferenceDetails";

// import Login from "../Login";;
// import Dashboard from "../Dashboard";
// import HomePage from "../PageContent/HomePage";
// import KundliMatchingPage from "../PageContent/KundliMatchingPage";
// import Resources from "../PageContent/Resouces/AllResources";
// import AddResourcesPage from "../PageContent/Resouces/AddResourcesPage";
// import EditResource from "../PageContent/Resouces/EditResource";
// import AllStories from "../PageContent/SuccessStories/AllStories";



// import Stories from './Components/Pages/PageContent/SuccessStories/AllStories';
// import AddStories from './Components/Pages/PageContent/SuccessStories/AddStories';
// import EditStories from './Components/Pages/PageContent/SuccessStories/EditStories';
// import Sliders from './Components/Pages/PageContent/HomePage/SliderData/All';
// import AddSliders from './Components/Pages/PageContent/HomePage/SliderData/Add';
// import Transcends from './Components/Pages/PageContent/HomePage/Transcends/All';
// import AddTranscends from './Components/Pages/PageContent/HomePage/Transcends/Add';
// import EditTranscends from './Components/Pages/PageContent/HomePage/Transcends/Edit';
// import Users from './Components/Pages/Users';
// import Main from './Components/Pages/Users/Main';

export const sidebarData = [
  {
    "name": "Dashboard",
    "link": "/Dashboard",
    "icon": "/images/Vector.png"
  },
  {
    "name": "Users List",
    "dropdown": {
      "label": "Users List",
      "items": [
        { name: "All User", link: "/Users" },
        { name: "Approve Pending", link: "/Approve-User" },
      
      ]
    }
   
   
  },
  {
    "name": "Pages",
    "dropdown": {
      "label": "Pages",
      "items": [
        { name: "HomePage", link: "/Pages/HomePage" },
        { name: "Home Slider", link: "/Pages/Sliders" },
        { name: "Home Transcends", link: "/Pages/Transcends" },
        { name: "Resoursces", link: "/Pages/Resources" },
        { name: "Success Stories", link: "/Pages/Stories" },
        { name: "About Page", link: "/Pages/AboutUs" },
        { name: "Instructors", link: "/Pages/Instructors" },
        { name: "Kundali Matching Page", link: "/Pages/KundliMatchingPage" },
        { name: "FAQs", link: "/Pages/FAQs" },
      ]
    }
  },
  {
    "name": "Contact Queries",
    "link": "/ContactQueries",
    "icon": "",
  }
];


export const userTabs = [
  {
    label: "Basic",
    content: <UserDetails />

  },
  {
    label: "Astro",
    content: <AstroDetails />
  },
  {
    label: "Family",
    content: <FamilyDetails />
  },
  {
    label: "Carrer",
    content: <CarrerDetails />
  },
  {
    label: "Spiritual",
    content: <SpiritualDetails />
  },
  {
    label: "Personality Questions",
    content: <PersonalityDetails />
  },
  {
    label: "Preferences",
    content: <PreferenceDetails />
  },
  {
    label: "Gallery",
    content: <Gallery />
  },
  {
    label: "Documents",
    content: <Documents />
  },

];

