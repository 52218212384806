import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
        
import Swal from "sweetalert2";
import Sidebar from "../../Layouts/Sidebar";
import { ApproveUser,DeleteUser, UserList, clearUserRes,uploadUserExelData } from "../../../Reducer/userSlice";
import { API_BASE_URL } from "../../../Reducer/api";
import DataTable from "react-data-table-component";
import Layout from "../../Layouts/Layout";
import DynamicDataTable from "../../../Utils/DynamicDataTable";
import MatchMakerModal from "./MatchMakerModal";
export default function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputField, setInputField] = useState({
 
    image: null,
  });
  const [open, setOpenModal] = useState(false);
  const [id, setId] = useState(null);
  const [file, setFile] = useState();
  const users = useSelector((state) => state.user.data);
  const success = useSelector((state) => state.user.success);
  const error = useSelector((state) => state.user.error);

  if (success && success.status == 200) {
    console.log("successs", success);
    if (success.data.data) {
      dispatch(clearUserRes());
    } else {
      Swal.fire({
        title: "Success!",
        text: success.data.message,
        icon: "success",
      });
      dispatch(clearUserRes());
      dispatch(UserList());
    }
  }

  if (error && error.status !== 200) {
    switch (error.status) {
      case 403:
        Swal.fire({
          title: "Error!",
          text: error.data.message,
          icon: "error",
        });
        dispatch(clearUserRes());
        break;
      default:
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        dispatch(clearUserRes());
    }
  }
  
  useEffect(() => {
    dispatch(UserList());
  }, []);
  const Imagepro=({record,API_BASE_URL})=>{
    return (
      <div>
          {record && record.profileImage && (
                  <img
                      src={API_BASE_URL + record.profileImage}
                      alt="Profile"
                      style={{ width: "100px", height: "auto" }}
                  />
              )}
      </div>
      
    )
  }
  const OpenModal = (id) => {
    setId(id);
    setOpenModal(true);
}
  const columns = [
    {
        
        field: "profileImage",
        header: 'Image',
        body: (rowData) => <Imagepro record={rowData} API_BASE_URL={API_BASE_URL} />
    
    },
    {
       
        field: "name",
        header: "Name",    
        right: true,
        filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
        field: "email",
        header: "Email",    
        right: true,
        filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
       
        field: "mobile",
        header: "Mobile",
      
      right: true,
      filter: true, filterPlaceholder: 'Search by name', style: { minWidth: '12rem' }
    },
    {
        
        field: "",
        header: "Action",
        filter: false, style: { minWidth: '12rem' },
        body: (record) => (
        <>
          <Link to={`/Users/${record._id}`}>
            <i className="fa fa-eye"></i>
            <Button label="Info" severity="info" icon="pi pi-eye" />
          </Link>
          <a onClick={() => delUser(record._id)}>
            <i className="fa fa-trash" aria-hidden="true"></i>
            <Button label="delete" severity="danger" icon="pi pi-trash" />
          </a>
        </>
      ),
    },{
        
      field: "",
      header: "Profile Status",
      filter: false, style: { minWidth: '12rem' },
      body: (record) => (
      <>
                    {record.profile_status && record.profile_status === "Approved" ?
                      <Button label="Approved" severity="success" />
                        :
                        <Button label="Approve" severity="Primary" onClick={() => ApproveProfile(record._id)} />
                        }
                        
        
      </>
    ),
  },{
        
    field: "",
    header: "Assigned To",
    filter: false, style: { minWidth: '12rem' },
    body: (record) => (
    <>
     
                      <>
                      {record.Assigned_to &&<Button label="Assigned" severity="success" />}
                        <Button label="Assign to MatchMaker" severity="info" onClick={() => OpenModal(record._id)}/>
                        
                        </>
                    
      
    </>
  ),
}
  ];

  function delUser(id) {
    if (window.confirm("Are you sure you want to delete?")) {
      let data = { id: id };
      dispatch(DeleteUser(data));
    }
  }
  function ApproveProfile(id) {
    let data = { id: id };
    dispatch(ApproveUser(data));
}
const handleSubmit = (e) => {
  e.preventDefault();
  try {
    let formData = new FormData();
   
    formData.append("image", inputField.image);
    dispatch(uploadUserExelData(formData));
  } catch (e) {
    console.error("Error during login:", e);
  }
};
const  handleChange=(e) =>{
  if (e.target.name == "image") {
    setInputField({
      ...inputField,
      [e.target.name]: e.target.files[0],
    });
    setFile(URL.createObjectURL(e.target.files[0]));
  } 
}
  return (
    <>
      <Layout>
        <div className="grid">
          <div className="col-12">
          {/* <div className="card">
              <h5>Select file for user upload</h5>

              <form onSubmit={handleSubmit}>
                <div className="p-fluid formgrid grid">
                  <div className="field col-12 md:col-6">
                    <label htmlFor="firstname2">exel file</label>
                    <InputText
                      id="firstname2"
                      type="file"
                      name="image"
                      
                      onChange={(e) => handleChange(e)}
                      required
                    />
                   
                  </div>
                  
                  <div class="col-12">
                    <div className="md:col-3">
                      <Button label="Submit" type="submit" severity="Primary" />
                    </div>
                  </div>
                </div>
              </form>
            </div> */}
            <div className="card">
              <h5>User List</h5>
              <DynamicDataTable
                data={users}
                columns={columns}
                loading={false}
                emptyMessage="No customers found."
            />
              
            </div>
          </div>
        </div>
        {open === true &&
                            <MatchMakerModal setOpenModal={setOpenModal} id={id} status={open} />
                        }
      </Layout>
     
    </>
  );
}
